<template>
	<div>
		<div class="sales-wrapper-white">
			<div class="sales-header">
				<div class="sales-header-text">
					<h1>Get More Orders</h1>
					<p>Fine Grounds connects your coffee shop to swaths of additional customers who want the convenience of ordering a specific cup of joe every day and having it sitting there ready and piping hot right when they arrive to pick it up. Best of all, connecting is as simple as one click for Square users!</p>
					<router-link to="/signup/business">Sign Up Now<span class="fal fa-arrow-right"></span></router-link>
				</div>
				<div class="sales-header-video"><img src="/images/photos/shop1.jpg" alt=""></div>
			</div>
		</div>
		<div class="sales-wrapper-grey">
			<div class="sales-grid-wrapper">
				<h3>Daily Orders You Can Count On!</h3>
				<div class="sales-grid-container">
					<router-link to="/signup/business" class="sales-grid-block">
						<h5>Sign Up</h5>
						<img src="/images/photos/shop3.jpg" alt="">
						<p>Business owners who use Square for sales can sign up to use Fine Grounds and start connecting with more coffee-loving customers right away!</p>
					</router-link>
					<router-link to="/signup/business" class="sales-grid-block">
						<h5>Enable Ordering</h5>
						<img src="/images/photos/shop4.jpg" alt="">
						<p>Once you're connected, simply enable ordering at one or more of your locations. There are <b>zero fees</b> associated with listing your shop or stand on Fine Grounds!</p>
					</router-link>
					<router-link to="/signup/business" class="sales-grid-block">
						<h5>Get Orders</h5>
						<img src="/images/photos/shop2.jpg" alt="">
						<p>Five minutes after you first clicked sign up, you can be live and ready to receive orders! And, if you have questions, we'll be here to help you <b>for free</b>!</p>
					</router-link>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

export default {

	name: 'BusinessSalesPage',

};

</script>
